import type { LinkProps } from "@remix-run/react";
import { Link } from "@remix-run/react";
import { twMerge } from "tailwind-merge";

export const LinkButton = ({
  children,
  className,
  loading,
  ...props
}: LinkProps & { loading?: boolean }) => {
  return (
    <Link
      className={twMerge(
        "rounded-md bg-sky-500 px-3 py-2.5 text-sm font-medium text-white inline-flex items-center gap-2",
        className
      )}
      {...props}
    >
      {children}
    </Link>
  );
};
